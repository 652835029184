
<template>
  <div>
    <v-tabs v-model="tab">
      <v-tab>
        1. Uroven
      </v-tab>
      <v-tab>
        2. Uroven
      </v-tab>

    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item> <admin-general-poll :admin="false"></admin-general-poll>
      </v-tab-item>
      <v-tab-item>
        <v-expansion-panels
          multiple
          hover
        >
          <v-expansion-panel
            v-for="poll in polls"
            v-bind:key="poll.id"
          >
            <v-expansion-panel-header v-if="poll.moderate"><strong>({{ poll.poll_category_name }}) {{ poll.order }}. {{poll.name}} (ID {{poll.id}})<v-icon v-show="!poll.active">mdi-eye-outline</v-icon></strong> <v-spacer></v-spacer> ANKETA</v-expansion-panel-header>
            <v-expansion-panel-content v-if="poll.moderate">
              <v-row dense>
                <v-col align="right">
                  <v-btn
                    small
                    color="success"
                    @click="createPollQuestion(poll.id)"
                  >Nova moznost</v-btn>
                </v-col>
              </v-row>

              <!-- <poll-detail
                :admin="false"
                :itemID="poll.id"
                :canAdmin="false"
                :canDelete="false"
                @sync-child="getPolls()"
              ></poll-detail> -->

              <v-expansion-panels
                multiple
                hover
              >
                <v-expansion-panel
                  v-for="question in poll.questions"
                  v-bind:key="question.id"
                >
                  <v-expansion-panel-header><strong>{{ question.order }}. {{ question.first_field }} {{ question.second_field }} <v-icon v-show="!question.active">mdi-eye-outline</v-icon></strong> <v-spacer></v-spacer> OTAZKA</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row dense>
                      <v-col align="right">
                        <v-btn
                          small
                          color="success"
                          @click="createPollSubQuestion(question.id)"
                        >Nova suvisiaca otazka</v-btn>
                      </v-col>
                    </v-row>

                    <poll-question-detail
                      :admin="false"
                      :itemID="question.id"
                    ></poll-question-detail>

                    <v-expansion-panels
                      multiple
                      hover
                    >
                      <v-expansion-panel
                        v-for="subQuestion in question.sub_questions"
                        v-bind:key="subQuestion.id"
                      >
                        <v-expansion-panel-header><strong>{{ subQuestion.order }}. {{ subQuestion.first_field }} {{ subQuestion.second_field }} <v-icon v-show="!subQuestion.active">mdi-eye-outline</v-icon></strong> <v-spacer></v-spacer> PODOTAZKA</v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <poll-question-detail
                            :itemID="subQuestion.id"
                            @sync-child="getPolls()"
                          ></poll-question-detail>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>

                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>

            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>
  
  
  <script>
import { APIService } from "@/http/APIService.js";
import adminGeneralPoll from "@/views/admin/GeneralPoll/GeneralPoll.vue";
// import PollDetail from "@/views/admin/GeneralPoll/PollDetail.vue";
import PollQuestionDetail from "@/views/admin/GeneralPoll/PollQuestionDetail.vue";

const apiService = new APIService();
export default {
  name: "Home",

  components: {
    adminGeneralPoll,
    // PollDetail,
    PollQuestionDetail
  },

  data() {
    return {
      polls: [],
      tab: 0
    }
  },

  mounted: function () {
    this.getPolls();
  },


  computed: {
  },

  methods: {
    getPolls() {
      apiService.getGeneralPollsAdmin("", "", 0, 0, []).then(response => {
        this.polls = response.results;
      });
    },
    createPoll(id) {
      apiService.createGeneralPollAdmin({ "poll_category": id }).then(() => {
        this.getPolls();
      });
    },
    createPollQuestion(id) {
      apiService.createGeneralPollQuestionAdmin({ "poll": id }).then(() => {
        this.getPolls();
      });
    },

    createPollSubQuestion(id) {
      apiService.createGeneralPollQuestionAdmin({ "parent_question": id }).then(() => {
        this.getPolls();
      });
    },

  },
};
  </script>


